import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
} from "@remix-run/react";
import { redirect, LoaderFunction, MetaFunction } from "@remix-run/node";
import styles from "~/styles/app.css";
import { getUserId } from "~/utils/session.server";
import { db } from "~/utils/db.server";
import { User } from "@prisma/client";

export const meta: MetaFunction = () => {
  return { title: "(╯°□°)╯ ┻━┻" };
};

export function links() {
  return [
    { rel: "stylesheet", href: styles },

    { rel: "preconnect", href: "https://fonts.googleapis.com" },
    {
      rel: "preconnect",
      href: "https://fonts.gstatic.com",
      crossOrigin: "true",
    },
    {
      href: [
        "https://fonts.googleapis.com/css2?",
        "family=Courier+Prime",
        "&family=Fredoka:wght@300;400;500;600;700",
        "&family=IBM+Plex+Serif",
        "&family=Lilita+One",
        "&family=Rubik:ital,wght@",
        "0,300;0,400;0,500;0,600;0,700;0,800;0,900;",
        "1,300;1,400;1,500;1,600;1,700;1,800;1,900",
        "&display=swap",
      ].join(""),
      rel: "stylesheet",
    },
  ];
}

const HUNDRED_YEARS = 60 * 60 * 24 * 365 * 10;

export const loader: LoaderFunction = async ({ request }) => {
  const url = new URL(request.url);
  const forwardedProto = request.headers.get("X-Forwarded-Proto");
  const host = request.headers.get("X-Forwarded-Host") ?? url.host;
  const pathname = url.pathname;
  const search = url.search ?? "";

  const userId = await getUserId(request);
  let user: User | null = null;

  if (userId) {
    user = await db.user.findFirst({ where: { id: userId } });
  }

  if (forwardedProto === "http") {
    return redirect(`https://${host}${pathname}${search}`, {
      headers: {
        "X-Forwarded-Proto": "https",
        "Strict-Transport-Security": `max-age=${HUNDRED_YEARS}`,
      },
    });
  }

  return {
    ENV: {
      DEV_PORT: process.env.DEV_PORT,
    },
    user: user ? { name: user.username, id: user.id } : null,
  };
};

export default function App() {
  const data = useLoaderData();

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <Outlet />
        <ScrollRestoration />
        <Scripts />
        <LiveReload port={+data.ENV.DEV_PORT} />
      </body>
    </html>
  );
}
